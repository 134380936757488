div.ie-not-supported {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

table.supported-browsers {
    margin: 0px auto;
}

a.supported-browser-link {
    cursor: pointer;
}