@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind theme;

@layer components {
  /*.btn-blue {*/
  /*    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;*/
  /*}*/
  /*.abcv {*/
  /*    @apply py-3 px-4 block w-full shadow-sm border text-gray-900 hover:bg-blue-700 focus:outline-none focus:ring-blue-400 focus:border-teal-500 border-warm-gray-300 rounded-md;*/
  /*inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500*/
  /*}*/
  .primary-btn {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;

  }

  .secondary-btn {
    @apply inline-flex justify-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  .tertiary-btn {
    @apply inline-flex justify-center bg-gray-100 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  .input-label {
    @apply block text-sm font-medium text-gray-900;
  }

  .input-ele {
    @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
  }

  .logo-img {
    @apply h-20 w-20 border border-gray-300 bg-white text-gray-300
  }

  .fixed-text-area {
    @apply shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md resize-none
  }

  .primary-user-btn {
    @apply inline-flex justify-center bg-custom-teal text-white py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-custom-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;

  }

  .disconnect-btn {
    @apply inline-flex justify-center bg-red-600 text-white py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;

  }

  .secondary-user-btn {
    @apply inline-flex justify-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  .api-danger-btn {
    @apply inline-flex justify-center bg-red-500  py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
  }

  .tertiary-user-btn {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-custom-teal-dark hover:bg-custom-teal-dark-double hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-teal-dark;

  }

  .app-switcher-default {
    @apply p-1 rounded-full text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
  }

  .app-switcher-dark-mode {
    @apply p-1 rounded-full text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
  }

  .pane-gray {
    @apply inline-block align-bottom bg-gray-200 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all
  }

  .pane-white {
    @apply inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all
  }

  .icon-button {
    @apply p-1 border border-transparent rounded-full shadow-sm text-gray-900 bg-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
  }

  .icon-button.disabled {
    @apply hover:text-gray-900 hover:bg-gray-300 focus:ring-0
  }

  .disabled {
    @apply cursor-not-allowed cursor-pointer opacity-30
  }

  .bage {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;

    &.with-icon {
      @apply px-3;

      .icon {
        @apply -ml-1 mr-1.5 h-3 w-3
      }
    }
  }

  .active-badge {
    @apply bg-blue-200
  }

  .switch-mini {
    @apply bg-gray-200 relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-skyblue;
  }

  .switch-mini .poniter-click {
    @apply translate-x-0 pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200;
  }

  .switch-mini.off {
    @apply bg-gray-200;
  }

  .switch-mini.on {
    @apply bg-green-400;
  }

  .switch-mini.on .poniter-click {
    @apply translate-x-4;
  }

  .switch-mini.off .poniter-click {
    @apply translate-x-0;
  }

}

/* Custom Css patch */

.dropdown-menu-list {
  position: absolute;
  z-index: 9;
  padding: 10px 9px;
  background: #fff;
  width: 248px;
  border: 1px solid #ececec;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.list-item {
  padding: 4px 1px;
  border-bottom: 1px solid #f3f3f3;
}

.dropdown-menu-list .list-item:last-child {
  border-bottom: 0px solid #f3f3f3;
}

.side-item:hover button {
  opacity: 1 !important;
}

li.authorise-item {
  position: relative;
  padding-left: 32px;
}

li.authorise-item:before {
  content: "✓";
  font-weight: bold;
  color: #3cb6ad;
  text-decoration: inherit;
  position: absolute;
  left: 12px;
}

.app-switcher {
  display: none;
  z-index: 100;
}

.app-switcher-holder:hover .app-switcher {
  display: block;
}

.help-question {
  display: none;
  z-index: 100;
}

.help-question-holder:hover .help-question {
  display: block;
}